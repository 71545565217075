import React from 'react';

export const GrowingFlexBoxVertical = (props) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: props.flexGrow,
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
            ...props.style
        }}>
            {props.children}
        </div>
    )
}
export const GrowingFlexBoxHorizontal = (props) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: props.flexGrow,
            alignItems: 'center',
            justifyContent: 'center',
            height: "100%",
            ...props.style
        }}>
            {props.children}
        </div>
    )
}
//export default GrowingFlexBox;