import React, { useState, useEffect } from 'react';

function Card3of1(props) {
  const [picUrl, setPicUrl] = useState(null);
  const [title, setTitle] = useState(null);
  const [text1, setText1] = useState(null);
  const [text2, setText2] = useState(null);
  const [text3, setText3] = useState(null);



  useEffect(() => {
    if (props.card === 1) {
      setPicUrl(`url(${require('../Assets/Card1.png')})`);
      setTitle('乘客端');
      setText1('一键叫车、预约叫车');
      setText2('不同于传统网约车的便捷功能');
    } else if (props.card === 2) {
      setPicUrl(`url(${require('../Assets/Card2.png')})`);
      setTitle('司机端');
      setText1('后台AI实时分析路况与打车需求');
      setText2('对运营车辆进行智能调度');
      setText3('提高运营车辆约载客率');
    } else if (props.card === 3) {
      setPicUrl(`url(${require('../Assets/Card3.png')})`);
      setTitle('车主端');
      setText1('收付款、招聘司机、处理证件');
      setText2('让车主更安心地管理车辆');
    }
  }, [])

  return (
    <div style={{
      width: 180,
      height: 480,
      marginLeft: 40,
      marginRight: 40,
      paddingLeft: 8,
      paddingRight: 8,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div style={{
        height: '70%',
        backgroundImage: picUrl,
        backgroundPosition: 'bottom center',
        backgroundSize: 'cover'
      }} />
      <div style={{
        color: 'rgb(255,227,116)',
        fontSize: 20,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center'
      }}>
        {title}
      </div>
      <div style={{
        color: 'white',
        fontSize: 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        fontFamily: "SourceHanSerif"

      }}>
        {text1}
        <br />
        {text2}
        <br />
        {text3 ? (<div>{text3}</div>) : (null)}
      </div>


    </div>
  )
}

export default Card3of1;