import React, { useState } from 'react';
import Page1 from "./Page/Page1_MO";
import Page2 from "./Page/Page2_MO";
import Page3 from "./Page/Page3_MO";
import Page4 from "./Page/Page4_MO";
import Page5 from "./Page/Page5_MO";
import Header from "./Component/Header_MO";


import ReactPageScroller from 'react-page-scroller';


function App_MO() {
  const [pageIndex, setPageIndex] = React.useState(0);
  return (
    <>
      <Header pageIndex={pageIndex} setPageIndex={setPageIndex} />
      <ReactPageScroller
        pageOnChange={(index) => { setPageIndex(index) }}
        customPageNumber={pageIndex}>
        <Page1 />
        <Page2 />
        <Page3 />
        <Page4 />
        <Page5 />
      </ReactPageScroller>
    </>
  );
}

export default App_MO;
