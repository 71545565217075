import React from 'react';

import { AppBar } from '@mui/material';

const NavLink = (props) => {
  return (
    <div
      className={'navLink'}
      style={{
        width: 72,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: props.highlightIndexes.indexOf(props.pageIndex) >= 0 ?
          "black"
          : "black",
        textDecoration: 'none',
        cursor: 'pointer'
      }}
      onClick={() => {
        props.setPageIndex(props.targetIndex);
      }}
    >

      {props.label}

    </div>

  )
}
export default (props) => {
  return (
    <AppBar
      position={'fixed'}
      style={{
        height: 48,
        backgroundColor: 'rgb(255,227,116)'
      }}
    >
      <div style={{
        width: '100%',
        height: "100%",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center', 
      }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
          <NavLink
            highlightIndexes={[0]}
            targetIndex={0}
            pageIndex={props.pageIndex}
            setPageIndex={props.setPageIndex}
            label={'首页'}
          />
          <NavLink
            highlightIndexes={[1]}
            targetIndex={1}
            pageIndex={props.pageIndex}
            setPageIndex={props.setPageIndex}
            label={'多端同步'}
          />
          <NavLink
            highlightIndexes={[2]}
            targetIndex={2}
            pageIndex={props.pageIndex}
            setPageIndex={props.setPageIndex}
            label={'高新技术'}
          />
          <NavLink
            highlightIndexes={[3]}
            targetIndex={3}
            pageIndex={props.pageIndex}
            setPageIndex={props.setPageIndex}
            label={'企业管理'}
          />
          <NavLink
            highlightIndexes={[4]}
            targetIndex={4}
            pageIndex={props.pageIndex}
            setPageIndex={props.setPageIndex}
            label={'联系我们'}
          />
        </div>
      </div>
    </AppBar>
  )
}