import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App_PC from './App_PC';
import App_MO from './App_MO';
import reportWebVitals from './reportWebVitals';
import ua from './Utils/GetUserAgent';

const root = ReactDOM.createRoot(document.getElementById('root'));
const App = () => {
  if (ua.android || ua.ios) {
    return <App_MO />
  } else {
    return <App_PC />
  }
}
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
