import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField  from '@mui/material/TextField';
import { sendMail } from "../Utils/SMTPService";
//import { makeStyles } from '@mui/styles'
//import styles from "@Assets/Styles/MuiKit";
import { GrowingFlexBoxVertical, GrowingFlexBoxHorizontal } from '../Component/GrowingFlexBox';
//import pallette from '@Assets/Styles/pallette';


//const useStyles = makeStyles(styles);

export default function Page5() {
  const [hasSentEmail, setHasSentEmail] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  //const classes = useStyles();
//console.log(name,email,message)
  return (
    <div style={{
      height: "100%",
      backgroundColor: 'white',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'SourceHanSerif',
      padding:20
    }}>


      <GrowingFlexBoxVertical flexGrow={1}>

      </GrowingFlexBoxVertical>

      <GrowingFlexBoxVertical flexGrow={4} style={{ backgroundColor: 'white', boxShadow: `4px 4px 4px white` }}>

        <section id="contact">
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={10}>
              <h2 >{"联系我们"}</h2>
              <h4 >
                {
                  "感谢您的关注。如果您希望了解更多，或者您有任何需要咨询的问题，可以在此留言，我们会尽快和您取得联系。"
                }
              </h4>
              <form>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField 
                      label="您的名字"
                      id="name"
                      variant="standard"
                      fullWidth
                      value={name}
                      onChange={(value)=>{setName(value.target.value)}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField 
                      label="您的Email"
                      id="email"
                      variant="standard"
                      fullWidth
                      value={email}
                      onChange={(value)=>{setEmail(value.target.value)}}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField 
                      label="您的留言"
                      id="message"
                      variant="standard"
                      fullWidth
                      //className: classes.textArea,
                      multiline
                      rows='5'
                      value={message}
                      onChange={(value)=>{setMessage(value.target.value)}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}/>
                  <Grid item xs={12} sm={12} md={2}>
                    <Button 
                      onClick={() => {
                        // let message = document.getElementById("message").value;
                        // let fromEmail = document.getElementById("email").value;
                        // let fromName = document.getElementById("name").value;

                        if (message && name && email && !hasSentEmail) {
                          sendMail({
                            Body: message,
                            FromEmail: email,
                            FromName: name,
                          })
                            .then((res) => {
                              setHasSentEmail(true);
                              alert("感谢您的关注，我们已收到您的留言。");
                              setEmail('')
                              setMessage('')
                              setName('')
                            })
                            // TODO:这个地方就算发信成功了接口也会反一个ParseError，怪
                            .catch((e) => {
                              setHasSentEmail(true);
                              alert("感谢您的关注，我们已收到您的留言。");
                              setEmail('')
                              setMessage('')
                              setName('')
                            });
                        } else {
                          if (hasSentEmail) {
                            alert("请勿重复操作");
                          } else {
                            alert("请输入您的联系方式。");
                          }
                        }
                      }}
                      // color='rgb(255,227,116)'
                    >
                      {"提交"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </section>
      </GrowingFlexBoxVertical>

      {/* <GrowingFlexBoxVertical flexGrow={2}>

      </GrowingFlexBoxVertical> */}
    </div>
  )
}






