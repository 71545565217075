import useViewport from "../Utils/UseViewport";
import Card2of1 from "../Component/Card2of1_MO";

function Page4() {

  const { width: windowWidth, height: windowHeight } = useViewport();

  return (
    <div style={{  //底层
      width: windowWidth,
      height: windowHeight,
      backgroundImage: `url(${require('../Assets/BG5.jpg')})`,
      backgroundPosition: 'center 80%',
      backgroundSize: '600%',
      backgroundRepeat: 'no-repeat'
    }}>
      <div style={{  //底层
        width: windowWidth - 16,
        height: windowHeight - 80,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(2px)',
        paddingTop: 80,
        paddingLeft: 8,
        paddingRight: 8,

      }}>
        <div style={{
          width: '100%',
          height: '20%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}>
          <div style={{  //标题
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'flex-end',
            alignItems: 'center',
            textAlign: 'center',
          }}>
            <text style={{
              color: 'white',
              fontSize: 40,
              margin: 12,
              fontFamily: "SourceHanSerif"
            }}>企业管理更方便</text>
            <text style={{
              color: 'rgb(255,227,116)',
              fontSize: 12,
              fontFamily: "SourceHanSerif"
            }}>
              界面简单人性化，简单易用
              <br />
              实现无纸化办公，减少繁重陈旧工作方式
            </text>
          </div>
        </div>
        <div style={{
          width: '100%',
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Card2of1 card={3} />
          <Card2of1 card={4} />
        </div>
      </div>
    </div>
  );
}

export default Page4;