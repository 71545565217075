import React, { useState, useEffect } from 'react';

import RoomIcon from '@mui/icons-material/Room';

function P1Card(props) {

  const [icon, setIcon] = useState(null);
  const [text, setText] = useState(null);

  useEffect(() => {
    if (props.card === 1) {
      setIcon(<RoomIcon />);
      setText('多端同步更灵活');
    } else if (props.card === 2) {
      setIcon(<RoomIcon />);
      setText('企业管理更方便');
    } else if (props.card === 3) {
      setIcon(<RoomIcon />);
      setText('高新技术更智慧');
    } 
  }, [])

  return (
    <div style={{
      display: 'flex',
      height: '100%',
      flex: 1,
      flexDirection: 'column'
    }}>
      <div style={{
        display: 'flex',
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'flex-end'
      }}>
        {icon}
      </div>
      <div style={{
        display: 'flex',
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <hr />
      </div>
      <div style={{
        display: 'flex',
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontSize:12
      }}>
        {text}
      </div>
    </div>
  )
}

export default P1Card;