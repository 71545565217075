import React, { useState, useEffect } from 'react';

function Card3of1(props) {
  const [picUrl, setPicUrl] = useState(null);
  const [title, setTitle] = useState(null);
  const [text1, setText1] = useState(null);



  useEffect(() => {
    if (props.card === 1) {
      setPicUrl(`url(${require('../Assets/Card3.png')})`);
      setTitle('智慧出行');
      setText1('提升人们的出行效率和便捷度，数字化创新正在从效率、安全、体验，以智慧出行以及城市可持续发展等多方面带来变革');
    } else if (props.card === 2) {
      setPicUrl(`url(${require('../Assets/Card2.png')})`);
      setTitle('大数据与AI技术');
      setText1('高效解决交通高峰期如何将车辆分布在道路网的问题。采集实时交通路况、传输和处理数据，及时将信息传递给出行者');
    } else if (props.card === 3) {
      setPicUrl(`url(${require('../Assets/Card2.png')})`);
      setTitle('实时车辆调度');
      setText1('结合大数据、AI能力及行业领先的服务标准，为出租车行业提供体系化、差异化、层次化的行业信息管理系统');
    } else if (props.card === 4) {
      setPicUrl(`url(${require('../Assets/Card2.png')})`);
      setTitle('详尽的信息记录');
      setText1('实时管理司机、车辆基础信息管理、运营数据、保险记录及安全情况，将帮助监管部门和企业提高司机服务质量，提升管理效率');
    }
  }, [])

  return (
    <div style={{
      height: 360,
      marginLeft: 8,
      marginRight: 8,
      paddingBottom: 20,
    }}>
      {props.card === 1 || props.card === 3 ? (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          height:'100%'
        }}>
          <div style={{
            height: '100%',
            width: '50%',
            backgroundImage: picUrl,
            backgroundPosition: 'center center',
            backgroundSize: '90%'
          }} />
          <div style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            padding: 12,
            justifyContent: 'center',
            alignItems: 'center',

          }}>
            <div style={{
              height: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              padding: 8,
              backgroundColor: 'rgba(0,0,0,0.5)',
              fontFamily: "SourceHanSerif"
            }}>
              <div style={{
                color: 'rgb(255,227,116)',
                fontSize: 24,
                marginBottom: 12
              }}>
                {title}
              </div>
              <div style={{
                color: 'white',
                fontSize: 12,
              }}>
                {text1}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          height:'100%'
        }}>

          <div style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            padding: 12,
            justifyContent: 'center',
            alignItems: 'center',

          }}>
            <div style={{
              height: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              padding: 8,
              backgroundColor: 'rgba(0,0,0,0.5)',
              fontFamily: "SourceHanSerif"
            }}>
              <div style={{
                color: 'rgb(255,227,116)',
                fontSize: 24,
                marginBottom: 12
              }}>
                {title}
              </div>
              <div style={{
                color: 'white',
                fontSize: 12,
              }}>
                {text1}
              </div>
            </div>
          </div>
          <div style={{
            height: '100%',
            width: '50%',
            backgroundImage: picUrl,
            backgroundPosition: 'center center',
            backgroundSize: '90%'
          }} />
        </div>
      )}
    </div>


  )
}

export default Card3of1;