import useViewport from "../Utils/UseViewport";

import P1Card from '../Component/P1Card';


function Page1() {

  const { width: windowWidth, height: windowHeight } = useViewport();

  return (
    <div style={{  //底层
      width: windowWidth,
      height: windowHeight,
      backgroundColor: 'rgb(40,41,43)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }}>

      <div style={{  //中间栏
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}>
        <div style={{  //中间栏上半
          width: '100%',
          height: '100%',
          display: 'flex',
          backgroundImage: `url(${require('../Assets/BG1.jpg')})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          flexDirection: 'column'
        }}>
          <div style={{  //中间栏标题
            width: '100%',
            display: 'flex',
            flex: 84,
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
              <text style={{
                color: 'white',
                fontSize: 80,
                margin: 12,
                fontFamily: "SourceHanSerif",

              }}>出租车管理系统</text>
              <text style={{
                color: 'rgb(255,227,116)',
                fontSize: 28,
                margin: 12,
                fontFamily: "SourceHanSerif"
              }}>
                出租车管理系统智慧解决方案
                <br />
                赋能传统行业，让企业管理更高效
              </text>
            </div>

          </div>

          <div style={{  //中间栏黄框
            width: '100%',
            display: 'flex',
            flex: 16
          }}>
            <div style={{ display: 'flex', flexGrow: 1, flexBasis: 0 }} />
            <div style={{
              display: 'flex',
              width: '28%',
              flexDirection: 'column',
              minWidth:300
            }}>
              <div style={{
                display: 'flex',
                flex: 5,
                backgroundColor: 'rgb(255,227,116)',
                padding: 16,
                flexDirection: 'row',
              }}>
                <P1Card card={1} />
                <P1Card card={2} />
                <P1Card card={3} />
              </div>
              <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', fontSize: 12, color: 'white', backgroundColor: 'rgb(40,41,43)' }}>
                了解更多
              </div>
            </div>
            <div style={{ display: 'flex', flexGrow: 1, flexBasis: 0 }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page1;