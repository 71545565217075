import useViewport from "../Utils/UseViewport";
import Card3of1 from "../Component/Card3of1_MO";

function Page2() {

  const { width: windowWidth, height: windowHeight } = useViewport();

  return (
    <div style={{  //底层
      width: windowWidth,
      height: windowHeight,
      backgroundImage: `url(${require('../Assets/BG4.jpg')})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    }}>
      <div style={{  //底层
        width: windowWidth -16,
        height: windowHeight -100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(2px)',
        paddingTop:120,
        paddingLeft:8,
        paddingRight:8,

      }}>
        <div style={{
          width: '100%',
          height: '30%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}>
          <div style={{  //标题
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'flex-end',
            alignItems: 'center',
            textAlign: 'center',
          }}>
            <text style={{
              color: 'white',
              fontSize: 40,
              margin: 12,
              fontFamily: "SourceHanSerif"
            }}>多端同步更灵活</text>
            <text style={{
              color: 'rgb(255,227,116)',
              fontSize: 12,
              fontFamily: "SourceHanSerif"
            }}>
              客户端、司机端、车主端、出租企业管理端四端联动
              <br />
              高效协同、灵活处理
            </text>
          </div>
        </div>
        <div style={{
          width: '100%',
          height: '70%',
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Card3of1 card={1}/>
          <Card3of1 card={2}/>
          <Card3of1 card={3}/>
        </div>
      </div>
    </div>
  );
}

export default Page2;